const d = document;
const body = d.querySelector('body');
const html = d.querySelector('html');
let accessibilityActive = document.querySelector('.hidden-access');

$(window).load(function() {
  window.setTimeout(function() {
    let slickTabs = document.querySelectorAll("[role='presentation'] > button");
    for (let i = 0; i < slickTabs.length; i++) {
      slickTabs[i].setAttribute('tabindex', '0')
    }
    let slickTabsNews = document.querySelectorAll(".wp-block-lazyblock-news-block [tabindex='-1']");
    for (let j = 0; j < slickTabs.length; j++) {
      slickTabsNews[j].setAttribute('tabindex', '0')
    }
  }, 1000);
});

// $(window).load(function() {
//   window.setTimeout(function() {
//     let slickTabsNews = document.querySelectorAll(".wp-block-lazyblock-news-block [tabindex='-1']");
//     for (let j = 0; j < slickTabs.length; j++) {
//       slickTabsNews[j].setAttribute('tabindex', '0')
//     }
//   }, 1000);
// });

$(window).keydown(function(event){
  if (event.keyCode == 9) {
        // accessibilityActive.style.display = 'block';
        body.classList.add('accessibility');
  } else {
    false
  }
});

$(window).click(function(){
  if (body.classList.contains('accessibility')) {
        body.classList.remove('accessibility');
  } else {
    false
  }
});

const search_wrapper = d.querySelector('.search-wrapper'); // search input
const ligipaasetavus = d.querySelector('.ligipaasetavus'); // options for ligipaasetavus
const search_item = d.querySelector('.search-item'); // magnifier icon
const ligipaasetavus_item = d.querySelector('.ligipaasetavus-item'); // ligipaasetavus option
const main_header_menu = d.querySelector('.main-header-menu'); // menu selection
const closeInput = d.querySelector('.katkesta-input'); // katkesta for input only

ligipaasetavus_item.addEventListener("click", function f(e) {
  e.preventDefault();

  if (ligipaasetavus.classList.contains('hide')) {
    ligipaasetavus.classList.toggle('hide');
    search_wrapper.classList.add('hide')
    main_header_menu.classList.add('hide')
  } else {
    ligipaasetavus.classList.add('hide');
    search_wrapper.classList.add('hide')
    main_header_menu.classList.toggle('hide')
  }
});

search_item.addEventListener("click", function f(e) {
  e.preventDefault();

  if (search_wrapper.classList.contains('hide')) {
    search_wrapper.classList.toggle('hide');
    ligipaasetavus.classList.add('hide')
    main_header_menu.classList.add('hide')
  } else {
    search_wrapper.classList.add('hide');
    ligipaasetavus.classList.add('hide')
    main_header_menu.classList.toggle('hide')
  }
});

closeInput.addEventListener('click', function f(e) {
  e.preventDefault();

  main_header_menu.classList.toggle('hide');
  search_wrapper.classList.toggle('hide');
})

const high_contrast = d.querySelector('#high-contrast');
const normal_colors = d.querySelector('#normal-colors');
const radio_bigger = d.querySelector('#radio-bigger');
const radio_big = d.querySelector('#radio-big');
const radio_normal = d.querySelector('#radio-normal');
const katkesta = d.querySelector('#katkesta');
const salvesta = d.querySelector('#salvesta');

window.onload = function () {
    getAdditionalClass()
    salvesta.addEventListener('click', setAdditionalClass)
    katkesta.addEventListener('click', clearAdditionalClass)
    transferList();
}

function setAdditionalClass() {

    if(radio_normal.checked){
        body.classList.remove('text-big')
        body.classList.remove('text-bigger')
        localStorage.removeItem('TextSize');
    } else if(radio_big.checked){
        body.classList.add('text-big')
        body.classList.remove('text-bigger')
        localStorage.setItem('TextSize', 'text-big');
    } else if(radio_bigger.checked){
        body.classList.remove('text-big')
        body.classList.add('text-bigger')
        localStorage.setItem('TextSize', 'text-bigger');
    }

    if(normal_colors.checked){
        body.classList.remove('high-contrast')
        localStorage.removeItem('Contrast');
    } else if(high_contrast.checked){
        body.classList.add('high-contrast')
        localStorage.setItem('Contrast', 'high-contrast');
    }
}

function clearAdditionalClass() {
    main_header_menu.classList.toggle('hide')
    ligipaasetavus.classList.toggle('hide')
    radio_normal.checked = false
    radio_big.checked = false
    radio_bigger.checked = false
    normal_colors.checked = false
    high_contrast.checked = false
}

function getAdditionalClass() {
    const TextSize = localStorage.getItem('TextSize');
    const Contrast = localStorage.getItem('Contrast');


    if(TextSize == 'text-big'){
        body.classList.add('text-big')
        radio_big.checked = true
    } else if(TextSize == 'text-bigger'){
        body.classList.add('text-bigger')
        radio_bigger.checked = true
    } else{
        radio_normal.checked = true
    }

    if(Contrast == 'high-contrast'){
        body.classList.add('high-contrast')
        high_contrast.checked = true
    } else{
        normal_colors.checked = true
    }
}

function transferList() {
    const slickDots = document.querySelectorAll('.content-slider ul.slick-dots > li > button, .wp-block-lazyblock-content-slider ul.slick-dots > li > button'); // desktop ul
    const dots = document.querySelectorAll('.content-slider ul.dots > li > a, .wp-block-lazyblock-content-slider ul.dots > li > a'); // phone ul

    // dots[0].style.color = "red";
    
    for(let i = 0; i < dots.length; i++) {
        slickDots[i].innerText = dots[i].innerText;

        // console.log("MobileDots", slickDots[i]);
        // console.log("Dots", dots[i]);
    }
}

// Slick Slider
$slick_slider = $('.mobile-slider');
settings = {
    dots: true,
    prevArrow: false,
    nextArrow: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    centerMode: false,
    mobileFirst: true,
    accessibility: true
}
$slick_slider.slick(settings);

$(document).foundation();

// reslick only if it's not slick()
$(window).on('load', function() {
    if ($(window).width() > 600) {
      if ($slick_slider.hasClass('slick-initialized')) {
        $slick_slider.slick('unslick');
      }
      return
    }
  
    if (!$slick_slider.hasClass('slick-initialized')) {
      return $slick_slider.slick(settings);
    }
});