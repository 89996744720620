//Sass
require("./scss/main.scss") ;


//Custom JS
require("./js/app");

import * as lightbox from 'lightbox2';

// require('../node_modules/foundation-sites/js/foundation.min.js')
// require('../node_modules/foundation-sites/css/foundation.min.css')

// import '@/node_modules/foundation-sites/js/foundation.min.js';